//SignIn Actions
export const START_USER_AUTHENTICATE = "START_USER_AUTHENTICATE";
export const SUCCESS_USER_AUTHENTICATE = "SUCCESS_USER_AUTHENTICATE";
export const ERROR_USER_AUTHENTICATE = "ERROR_USER_AUTHENTICATE";

//ForgotPassword Actions
export const START_FORGOT_PASSWORD = "START_FORGOT_PASSWORD";
export const SUCCESS_FORGOT_PASSWORD = "SUCCESS_FORGOT_PASSWORD";
export const ERROR_FORGOT_PASSWORD = "ERROR_FORGOT_PASSWORD";

//Phone Number Verification
export const START_VERIFY_MOBILE_NUMBER = "START_VERIFY_MOBILE_NUMBER";
export const SUCCESS_VERIFY_MOBILE_NUMBER = "SUCCESS_VERIFY_MOBILE_NUMBER";
export const ERROR_VERIFY_MOBILE_NUMBER = "ERROR_VERIFY_MOBILE_NUMBER";

//Create Otp
export const START_VERIFY_OTP = "START_VERIFY_OTP";
export const SUCCESS_VERIFY_OTP = "SUCCESS_VERIFY_OTP";
export const ERROR_VERIFY_OTP = "ERROR_VERIFY_OTP";

//Logout Actions
export const LOGOUT = "LOGOUT";
export const ERROR_LOGOUT = "ERROR_LOGOUT";

//Change Password Action 
export const START_CHANGE_PASSWORD = "START_CHANGE_PASSWORD"
export const SUCCESS_CHANGE_PASSWORD = "SUCCESS_CHANGE_PASSWORD"
export const ERROR_CHANGE_PASSWORD = "ERROR_CHANGE_PASSWORD"


//Empty Message
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";


//Get User deatails
export const FETCH_USER_LOGGED_IN_DETAIL = "FETCH_USER_LOGGED_IN_DETAIL"
export const GET_USER_LOGGED_IN_DETAIL = "GET_USER_LOGGED_IN_DETAIL"
export const USER_LOGGED_IN_DETAIL_ERROR = "USER_LOGGED_IN_DETAIL_ERROR"

