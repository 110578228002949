import {call, put, take} from "redux-saga/effects";
import {
    FETCH_BUSINESS_INFO_DETAIL,
    GET_BUSINESS_INFO_DETAIL,
    ERROR_BUSINESS_INFO_DETAIL,
} from '../actions/BusinessInfoActions';
import {
    BusinessInfoDetails
} from '../../api/businessInfoApi';

//START:Business Info details
export function* BusinessInfoSagas() {
    while (true) {
        const {userId} = yield take(FETCH_BUSINESS_INFO_DETAIL);
        const response = yield call(BusinessInfoDetails,userId);
        if (response.status) {
            yield put({
                type: GET_BUSINESS_INFO_DETAIL,
                payload: response.data
            });
        } else {
            yield put({
                type: ERROR_BUSINESS_INFO_DETAIL
            });
        }
    }
}

//END:Business Info details