//Empty
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";

//Analytics Details
export const GET_ANALYTICS_DETAILS_START = "GET_ANALYTICS_DETAILS_START";
export const GET_ANALYTICS_DETAILS_SUCCESS = "GET_ANALYTICS_DETAILS_SUCCESS";
export const GET_ANALYTICS_DETAILS_ERROR = "GET_ANALYTICS_DETAILS_ERROR";

//Analytics Month counts
export const GET_MONTH_COUNTS_START = "GET_MONTH_COUNTS_START";
export const GET_MONTH_COUNTS_SUCCESS = "GET_MONTH_COUNTS_SUCCESS";
export const GET_MONTH_COUNTS_ERROR = "GET_MONTH_COUNTS_ERROR";

//Analytics Daily counts
export const GET_DAILY_COUNTS_START = "GET_DAILY_COUNTS_START";
export const GET_DAILY_COUNTS_SUCCESS = "GET_DAILY_COUNTS_SUCCESS";
export const GET_DAILY_COUNTS_ERROR = "GET_DAILY_COUNTS_ERROR";