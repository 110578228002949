import { act } from "@testing-library/react";
import {
  START_COLLEAGUES_TEAM,
  SUCCESS_COLLEAGUES_TEAM,
  ERROR_COLLEAGUES_TEAM,
  FETCH_COLLEAGUES_TEAM,
  GET_COLLEAGUES_TEAM_DETAIL,
  ERROR_COLLEAGUES_TEAM_DETAIL,
  EMPTY_MESSAGE,
  FETCH_VISTOR_COLLEAGUE_TEAM,
  GET_VISTOR_COLLEAGUE_TEAM,
  ERROR_VISTOR_COLLEAGUE_TEAM,
  START_DELETE_COLLEAGUES,
  SUCCESS_DELETE_COLLEAGUES,
  ERROR_DELETE_COLLEAGUES,
} from "../actions/ColleaguesAndTeamActions";

const initialState = {
  isLoading: false,
  isError: false,
  colleaguesAddSuccessMessage: "",
  errorMessage: "",
  isColleagueTeamDetail: false,
  colleaguesTeamDetail: {},
  isColleagueTeamVistor: false,
  colleaguesTeamVistor: [],
  theme_color: {},
  isValidUserId: true,
  deleteColleagueIsLoading: false,
  deleteColleagueErrorMessage: "",
  deleteColleagueSuccessMessage: "",
};

const ColleaguesAndTeamReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Add Colleagues & Team Case
    case START_COLLEAGUES_TEAM: {
      return {
        ...prevState,
        isLoading: true,
        isError: false,
      };
    }
    case SUCCESS_COLLEAGUES_TEAM: {
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        colleaguesAddSuccessMessage: action.message,
      };
    }
    case ERROR_COLLEAGUES_TEAM: {
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        errorMessage: action.message,
      };
    }
    //END:Add Colleagues & Team Case
    //START: Get Add Colleagues & Team Case
    case FETCH_VISTOR_COLLEAGUE_TEAM: {
      return {
        ...prevState,
        isColleagueTeamDetail: true,
      };
    }
    case GET_COLLEAGUES_TEAM_DETAIL:
      return {
        ...prevState,
        isColleagueTeamDetail: false,
        colleaguesTeamDetail: action.payload,
      };
    case ERROR_COLLEAGUES_TEAM_DETAIL:
      return {
        ...prevState,
        colleaguesTeamDetail: {},
        isColleagueTeamDetail: false,
      };
    //END: Get Add Colleagues & Team Case

    //START: Vistor view Team colleaguse list
    case FETCH_COLLEAGUES_TEAM: {
      return {
        ...prevState,
        isColleagueTeamVistor: true,
      };
    }
    case GET_VISTOR_COLLEAGUE_TEAM:
      return {
        ...prevState,
        isColleagueTeamVistor: false,
        colleaguesTeamVistor: action.payload,
        theme_color: action.themeData,
        isValidUserId: true,
      };
    case ERROR_VISTOR_COLLEAGUE_TEAM:
      return {
        ...prevState,
        colleaguesTeamVistor: [],
        isColleagueTeamVistor: false,
        theme_color: {},
        isValidUserId: action.isValidUserId,
      };
    //END: Vistor view Team colleaguse list
    //Emapty message
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //START: Delete Colleagues
    case START_DELETE_COLLEAGUES:
      return {
        ...prevState,
        deleteColleagueIsLoading: true,
        deleteColleagueErrorMessage: "",
      };
    case SUCCESS_DELETE_COLLEAGUES:
      return {
        ...prevState,
        deleteColleagueIsLoading: false,
        deleteColleagueSuccessMessage: action.message,
      };
    case ERROR_DELETE_COLLEAGUES:
      return {
        ...prevState,
        deleteColleagueIsLoading: false,
        deleteColleagueErrorMessage: action.message,
      };
    //END: Delete Colleagues
    default:
      return prevState;
  }
};

export default ColleaguesAndTeamReducer;
