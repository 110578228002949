import React from "react";
import Routes from "./Routes";
import { Provider, useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import store from "./redux/store";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useTheme } from "./hooks/useTheme";
import CF from "./utils/CommonFunction";
import { SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE } from "./redux/actions/ColorThemeActions";

function App() {
  return (
    <Provider store={store}>
      <WithTheme />
    </Provider>
  );
}
export default App;

function WithTheme() {
  const dispatch = useDispatch();
  const { theme, themeLoaded } = useTheme();
  React.useEffect(() => {
    const localTheme = CF.getLocalStorage("_THEME", true);
    dispatch({
      type: SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
      _theme: localTheme,
    });
    document.body.style.backgroundColor =
      localTheme?.theme_mode === "dark" ? "black" : "white";
  }, []);

  return (
    <>
      {themeLoaded && (
        <div className="App">
          <NotificationContainer />
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </div>
      )}
    </>
  );
}
