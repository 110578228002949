import { call, put, take } from "redux-saga/effects";
import {
  START_SAVE_SETTING,
  SUCCESS_SAVE_SETTING,
  ERROR_SAVE_SETTING,
  FETCH_SETTING_DETAIL,
  GET_SETTING_DETAIL,
  ERROR_SETTING_DETAIL,
} from "../actions/SettingActions";
import { saveSetting, settingDataDetails } from "../../api/settingApi";

//START: Setting
export function* settingSagas() {
  while (true) {
    const {
      type_of_account,
      name,
      email,
      mobile,
      subs_expiration_date,
      country_code,
      joined_groups,
    } = yield take(START_SAVE_SETTING);
    const response = yield call(
      saveSetting,
      type_of_account,
      name,
      email,
      mobile,
      subs_expiration_date,
      country_code,
      joined_groups
    );

    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_SAVE_SETTING,
        message,
      });
    } else {
      yield put({
        type: ERROR_SAVE_SETTING,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}
//END:Setting

//START:Setting data details
export function* getSettingUserDetailSaga() {
  while (true) {
    yield take(FETCH_SETTING_DETAIL);
    const response = yield call(settingDataDetails);
    if (response.status) {
      yield put({
        type: GET_SETTING_DETAIL,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_SETTING_DETAIL,
      });
    }
  }
}
//END:Setting data details
