import {
  FETCH_PREMIUM_PLANS,
  GET_PREMIUM_PLANS_DETAIL,
  ERROR_PREMIUM_PLANS_DETAIL,
  START_PREMIUM_PLANS,
  SUCCESS_PREMIUM_PLANS,
  ERROR_PREMIUM_PLANS,
  EMPTY_MESSAGE,
  START_ENTERPRISE_OFFER,
  SUCCESS_ENTERPRISE_OFFER,
  ERROR_ENTERPRISE_OFFER,
  FETCH_PREMIUM_PLANS_VIEW,
  GET_PREMIUM_PLANS_DETAIL_VIEW,
  ERROR_PREMIUM_PLANS_DETAIL_VIEW,
} from "../actions/PremiumPlansActions";

const initialState = {
  isPremiumPlansDetail: false,
  premiumPlansDetail: {},
  isLoadingPremiumPlan: false,
  isError: false,
  premiumPlanSuccessMessage: "",
  errorMessage: "",
  errorBag: {},
  subscriptionPlanErrorBag: {},
  enterpriseOfferSuccess: "",
  isLoadingEnterpriseOffer: false,
  isUserLoading: false,
  premiumPlansUserDetail: {},
};

const PremiumplansReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Get Premium plans details case
    case FETCH_PREMIUM_PLANS: {
      return {
        ...prevState,
        isPremiumPlansDetail: true,
      };
    }
    case GET_PREMIUM_PLANS_DETAIL:
      return {
        ...prevState,
        isPremiumPlansDetail: false,
        premiumPlansDetail: action.payload,
      };
    case ERROR_PREMIUM_PLANS_DETAIL:
      return {
        ...prevState,
        premiumPlansDetail: {},
        isPremiumPlansDetail: false,
      };
    //END: Get Premium plans  details case

    case FETCH_PREMIUM_PLANS_VIEW: {
      return {
        ...prevState,
        isUserLoading: true,
      };
    }
    case GET_PREMIUM_PLANS_DETAIL_VIEW:
      let theme = {};
      if (action.payload.themecolor) {
        theme = action.payload.themecolor;
      }
      return {
        ...prevState,
        isUserLoading: false,
        premiumPlansUserDetail: action.payload,
        _theme: theme,
      };
    case ERROR_PREMIUM_PLANS_DETAIL_VIEW:
      return {
        ...prevState,
        isUserLoading: false,
        premiumPlansUserDetail: {},
      };

    //START: Premium plans subscribe
    case START_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: true,
        isError: false,
      };
    }
    case SUCCESS_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: false,
        premiumPlanSuccessMessage: action.message,
      };
    }
    case ERROR_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: false,
        isError: true,
        errorMessage: action.message,
        subscriptionPlanErrorBag: action.subscriptionPlanErrorBag,
      };
    }
    //END:Premium plans subscribe
    //START: Enterprise offer
    case START_ENTERPRISE_OFFER: {
      return {
        ...prevState,
        isLoadingEnterpriseOffer: true,
      };
    }
    case SUCCESS_ENTERPRISE_OFFER: {
      return {
        ...prevState,
        isLoadingEnterpriseOffer: false,
        enterpriseOfferSuccess: action.message,
      };
    }
    case ERROR_ENTERPRISE_OFFER: {
      return {
        ...prevState,
        isLoadingEnterpriseOffer: false,
        enterpriseOffererrorMessage: action.message,
        errorBag: action.errorBag,
      };
    }
    //END: Enterprise offer
    //START: Error Empty Message
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //END: Error Empty Message
    default:
      return prevState;
  }
};

export default PremiumplansReducer;
