import {
  START_USER_AUTHENTICATE,
  SUCCESS_USER_AUTHENTICATE,
  ERROR_USER_AUTHENTICATE,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  ERROR_CHANGE_PASSWORD,
  EMPTY_MESSAGE,
  FETCH_USER_LOGGED_IN_DETAIL,
  GET_USER_LOGGED_IN_DETAIL,
  USER_LOGGED_IN_DETAIL_ERROR,
  LOGOUT,
  ERROR_LOGOUT,
  START_VERIFY_MOBILE_NUMBER,
  SUCCESS_VERIFY_MOBILE_NUMBER,
  ERROR_VERIFY_MOBILE_NUMBER,
  START_VERIFY_OTP,
  SUCCESS_VERIFY_OTP,
  ERROR_VERIFY_OTP,
} from "../actions/User";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  isError: false,
  loggedInUserData: {},
  errorMessage: "",
  successMessage: "",
  forgotPasswordSuccessMessage: "",
  isLoadingForgotPassword: false,
  forgotPasswordErrorMessage: "",
  changePasswordSuccessMessage: "",
  changePasswordErrorMessage: "",
  isGettingLoginUserDetail: false,
  userDetail: {},
  isFirstTimeLogin: false,
  token: "",
  isLogoutLoading: false,
  isLoadingPhoneVerification: false,
  phoneVerificationSuccessMessage: "",
  phoneVerificationErrorMessage: "",
  isLoadingPhoneVerificationOtp: false,
  otpSuccessMessage: "",
  otpErrorMessage: "",
  errorBag: {},
};
const UserReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case START_USER_AUTHENTICATE: {
      return {
        ...prevState,
        isLoading: true,
        isError: false,
      };
    }
    case SUCCESS_USER_AUTHENTICATE: {
      const { loggedInUserData, successMessage, isFirstTimeLogin } = action;
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        isLoggedIn: true,
        loggedInUserData: loggedInUserData,
        successMessage: successMessage,
        isFirstTimeLogin,
      };
    }
    case ERROR_USER_AUTHENTICATE: {
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        isLoggedIn: false,
        errorMessage: action.errorMessage,
      };
    }

    //START :Forgot Password Case
    case START_FORGOT_PASSWORD:
      return {
        ...prevState,
        isLoadingForgotPassword: true,
        forgotPasswordSuccessMessage: "",
        forgotPasswordErrorMessage: "",
      };
    case SUCCESS_FORGOT_PASSWORD:
      return {
        ...prevState,
        isLoadingForgotPassword: false,
        forgotPasswordSuccessMessage: action.message,
      };
    case ERROR_FORGOT_PASSWORD:
      return {
        ...prevState,
        isLoadingForgotPassword: false,
        forgotPasswordErrorMessage: action.errorMessage,
      };
    //END :Forgot Password Case
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //START :Change Password Case
    case START_CHANGE_PASSWORD: {
      return {
        ...prevState,
        isLoading: true,
        isError: false,
        changePasswordSuccessMessage: "",
        changePasswordErrorMessage: "",
      };
    }
    case SUCCESS_CHANGE_PASSWORD: {
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        changePasswordSuccessMessage: action.message,
      };
    }
    case ERROR_CHANGE_PASSWORD: {
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        changePasswordErrorMessage: action.message,
      };
    }
    //END:Change Password Case

    //START: Get user details case
    case FETCH_USER_LOGGED_IN_DETAIL: {
      return {
        ...prevState,
        isGettingLoginUserDetail: true,
      };
    }
    case GET_USER_LOGGED_IN_DETAIL:
      return {
        ...prevState,
        // isLoggedIn: true,
        isGettingLoginUserDetail: false,
        userDetail: action.payload,
      };
    case USER_LOGGED_IN_DETAIL_ERROR:
      return {
        ...prevState,
        userDetail: {},
        isGettingLoginUserDetail: false,
      };
    //END: Get user details case

    //START: Logout case
    case LOGOUT: {
      // return initialState
      return {
        ...initialState,
        isLogoutLoading: true,
      };
    }
    case ERROR_LOGOUT:
      return {
        ...prevState,
        isLogoutLoading: false,
      };
    //END: Logout case
    //START :Phone Verification Otp Case
    case START_VERIFY_OTP:
      return {
        ...prevState,
        isLoadingPhoneVerificationOtp: true,
        otpSuccessMessage: "",
        otpErrorMessage: "",
      };
    case SUCCESS_VERIFY_OTP:
      return {
        ...prevState,
        isLoadingPhoneVerificationOtp: false,
        otpSuccessMessage: action.message,
      };
    case ERROR_VERIFY_OTP:
      return {
        ...prevState,
        isLoadingPhoneVerificationOtp: false,
        otpErrorMessage: action.message,
      };
    //END :Phone Verification Otp Case

    //START :Phone Verification Case
    case START_VERIFY_MOBILE_NUMBER:
      return {
        ...prevState,
        isLoadingPhoneVerification: true,
        phoneVerificationSuccessMessage: "",
        phoneVerificationErrorMessage: "",
      };
    case SUCCESS_VERIFY_MOBILE_NUMBER:
      const {
        loggedInUserData,
        phoneVerificationSuccessMessage,
        isFirstTimeLogin,
      } = action;
      return {
        ...prevState,
        isLoadingPhoneVerification: false,
        isLoggedIn: true,
        loggedInUserData: loggedInUserData,
        phoneVerificationSuccessMessage: phoneVerificationSuccessMessage,
        isFirstTimeLogin,
      };
    case ERROR_VERIFY_MOBILE_NUMBER:
      return {
        ...prevState,
        isLoadingPhoneVerification: false,
        isLoggedIn: false,
        phoneVerificationErrorMessage: action.message,
        errorBag: action.errorBag,
      };
    //END :Phone Verification Case
    default:
      return prevState;
  }
};

export default UserReducer;
