import {createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import {composeWithDevTools} from "redux-devtools-extension";
import rootReducer from "./reducers/rootReducer";
import rootSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    rootReducer,
    composeWithDevTools({trace: true})(applyMiddleware(sagaMiddleware))
);
if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
        module.hot.accept('./reducers/rootReducer', () => {
            store.replaceReducer(rootReducer);
        });
    }
}
sagaMiddleware.run(rootSaga);
export default store;
