import { call, put, take } from "redux-saga/effects";
import {
  START_PREMIUM_PLANS,
  SUCCESS_PREMIUM_PLANS,
  ERROR_PREMIUM_PLANS,
} from "../actions/PremiumPlansActions";
import { PremiumPlansSubscribe } from "../../api/premiumPlansApi";

//START: Premium plans subscribe
export function* subscriptionPlanSagas() {
  while (true) {
    const { payload } = yield take(START_PREMIUM_PLANS);
    const response = yield call(PremiumPlansSubscribe, payload);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_PREMIUM_PLANS,
        message,
      });
    } else {
      yield put({
        type: ERROR_PREMIUM_PLANS,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}

//END:Premium plans subscribe
