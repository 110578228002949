import API from "../api";

//Profile Save
export function profileSave(formData) {
  return API.postWithFormData("/save_profile_data", formData);
}

//Profile List
export function profileDetails() {
  return API.get("/get_profile_data");
}

//Save Media
export function GallearyPhotoUpload(formData) {
  return API.postWithFormData("/save_media", formData);
}

//Delete Photo
export function GallearyPhotoDelete(id) {
  return API.get(`/delete_photo_gallery/${id}`);
}

//Country Code List
export function countryCodeList() {
  return API.get("/country_code");
}
