import {
    START_SAVE_SETTING,
    SUCCESS_SAVE_SETTING,
    ERROR_SAVE_SETTING,
    FETCH_SETTING_DETAIL,
    GET_SETTING_DETAIL,
    ERROR_SETTING_DETAIL,
    EMPTY_MESSAGE
} from '../actions/SettingActions';

const initialState = {
    isLoading: false,
    isError: false,
    settingSuccessMessage: '',
    settingErrorMessage: '',
    isSettingUserDetail: false,
    settingDetail: {},
    errorBag: {}
};


const SettingReducer = (prevState = initialState, action) => {
    switch (action.type) {
        case START_SAVE_SETTING: {
            return {
                ...prevState,
                isLoading: true,
                isError: false,
            }
        }
        case SUCCESS_SAVE_SETTING: {
            return {
                ...prevState,
                isLoading: false,
                isError: false,
                settingSuccessMessage: action.message,
            }
        }
        case ERROR_SAVE_SETTING: {
            return {
                ...prevState,
                isLoading: false,
                isError: true,
                settingErrorMessage: action.message,
                errorBag: action.errorBag
            }
        }
        //START: Get setting details case
        case FETCH_SETTING_DETAIL: {
            return {
                ...prevState,
                isSettingUserDetail: true,
            };
        }
        case GET_SETTING_DETAIL:
            return {
                ...prevState,
                isLoggedIn: true,
                isSettingUserDetail: false,
                settingDetail: action.payload,
            };
        case ERROR_SETTING_DETAIL:
            return {
                ...prevState,
                settingDetail: {},
                isSettingUserDetail: false,
            };
        //END: Get setting details case
        case EMPTY_MESSAGE: {
            return {
                ...prevState,
                [action.key]: initialState[action.key],
            };
        }
        default:
            return prevState
    }
};


export default SettingReducer;