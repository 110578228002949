import { call, put, take } from "redux-saga/effects";
import {
  GET_ANALYTICS_DETAILS_START,
  GET_ANALYTICS_DETAILS_SUCCESS,
  GET_ANALYTICS_DETAILS_ERROR,
  GET_MONTH_COUNTS_START,
  GET_MONTH_COUNTS_SUCCESS,
  GET_MONTH_COUNTS_ERROR,
  GET_DAILY_COUNTS_START,
  GET_DAILY_COUNTS_SUCCESS,
  GET_DAILY_COUNTS_ERROR,
} from "../actions/AnalyticsActions";
import {
  getAnalyticsDetails,
  getAnalyticsMonthCounts,
  getAnalyticsDailyCounts,
} from "../../api/analyticsApi";

//START: Analytics Details
export function* AnalyticsDetailsSagas() {
  while (true) {
    const { startFrom, endTo } = yield take(GET_ANALYTICS_DETAILS_START);
    const response = yield call(getAnalyticsDetails, startFrom, endTo);
    if (response.status) {
      yield put({
        type: GET_ANALYTICS_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_ANALYTICS_DETAILS_ERROR,
      });
    }
  }
}
//END:Analytics

//START: Month Counts
export function* AnalyticsMonthCountsSagas() {
  while (true) {
    yield take(GET_MONTH_COUNTS_START);
    const response = yield call(getAnalyticsMonthCounts);
    if (response.status) {
      yield put({
        type: GET_MONTH_COUNTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_MONTH_COUNTS_ERROR,
      });
    }
  }
}
//END: Month Counts

//START: Month Counts
export function* AnalyticsDailyCountsSagas() {
  while (true) {
    yield take(GET_DAILY_COUNTS_START);
    const response = yield call(getAnalyticsDailyCounts);
    if (response.status) {
      yield put({
        type: GET_DAILY_COUNTS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_DAILY_COUNTS_ERROR,
      });
    }
  }
}
//END: Month Counts
