import API from "../api";

//Get Analytics Details
export function getAnalyticsDetails(startFrom, endTo) {
  return API.get(`/get_analytics?date_from=${startFrom}&date_to=${endTo}`);
}

//Get Daily Count
export function getAnalyticsDailyCounts() {
  return API.get("/get_daily_analytics");
}

//Get Month Count
export function getAnalyticsMonthCounts() {
  return API.get("/get_month_analytics");
}
