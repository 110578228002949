import { call, put, take } from "redux-saga/effects";
import {
  START_USER_AUTHENTICATE,
  SUCCESS_USER_AUTHENTICATE,
  ERROR_USER_AUTHENTICATE,
  START_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  ERROR_FORGOT_PASSWORD,
  START_CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  ERROR_CHANGE_PASSWORD,
  FETCH_USER_LOGGED_IN_DETAIL,
  GET_USER_LOGGED_IN_DETAIL,
  USER_LOGGED_IN_DETAIL_ERROR,
  LOGOUT,
  ERROR_LOGOUT,
  START_VERIFY_MOBILE_NUMBER,
  SUCCESS_VERIFY_MOBILE_NUMBER,
  ERROR_VERIFY_MOBILE_NUMBER,
  START_VERIFY_OTP,
  SUCCESS_VERIFY_OTP,
  ERROR_VERIFY_OTP,
} from "../actions/User";
import {
  login,
  forgotPasswordRequest,
  changePassword,
  userData,
  logout,
  PhoneVerificationLogin,
  PhoneVerificationOtp,
} from "../../api/user";
import API from "../../api";

export function* loginSaga() {
  while (true) {
    const { email, password } = yield take(START_USER_AUTHENTICATE);
    const response = yield call(login, email, password);
    const { message, status } = response;
    if (response.status === true) {
      yield call(API.setAccessTokenInLocalStorage, response.token);
      yield put({
        type: SUCCESS_USER_AUTHENTICATE,
        successMessage: message,
        status: status,
        isFirstTimeLogin: response["first-time"] === 1,
      });
    } else {
      let { errors } = response;
      let errorMessage = null;
      if (errors?.hasOwnProperty("email")) {
        errorMessage = errors.email[0];
      } else if (errors.length > 0 && errors[0].length > 0) {
        errorMessage = errors[0][0];
      }
      yield put({
        type: ERROR_USER_AUTHENTICATE,
        errorMessage: errorMessage || "Something went wrong, please try again",
      });
    }
  }
}

//  START :Forgot Password
export function* forgotPasswordSaga() {
  while (true) {
    const { email } = yield take(START_FORGOT_PASSWORD);
    const response = yield call(forgotPasswordRequest, email);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_FORGOT_PASSWORD,
        message: message,
      });
    } else {
      let errorMessage = null;
      if (
        response.hasOwnProperty("data") &&
        response.data.hasOwnProperty("errors") &&
        response.data.errors.hasOwnProperty("email")
      ) {
        errorMessage = response.data.errors.email[0];
      }
      yield put({
        type: ERROR_FORGOT_PASSWORD,
        errorMessage: errorMessage || message,
      });
    }
  }
}
//  END :Forgot Password

//  START :Change Password
export function* changePasswordSaga() {
  while (true) {
    const { currentPassword, newPassword, confirmPassword } = yield take(
      START_CHANGE_PASSWORD
    );
    const response = yield call(
      changePassword,
      currentPassword,
      newPassword,
      confirmPassword
    );
    const { message } = response;
    try {
      if (response.status) {
        yield put({
          type: SUCCESS_CHANGE_PASSWORD,
          message,
        });
      } else {
        yield put({
          type: ERROR_CHANGE_PASSWORD,
          message: message,
        });
      }
    } catch (e) {
      yield put({
        type: ERROR_CHANGE_PASSWORD,
        message: message,
      });
    }
  }
}
//END:Change Password

//  START : Phone Veriffication

export function* PhoneVerificationSaga() {
  while (true) {
    const { mobile, otp } = yield take(START_VERIFY_MOBILE_NUMBER);
    const response = yield call(PhoneVerificationLogin, mobile, otp);
    const { message } = response;
    if (response.status === true) {
      yield call(API.setAccessTokenInLocalStorage, response.token);
      yield put({
        type: SUCCESS_VERIFY_MOBILE_NUMBER,
        phoneVerificationSuccessMessage: message,
        isFirstTimeLogin: response["first-time"] === 1,
      });
    } else {
      if (response.data.hasOwnProperty("errors")) {
        response.data.error = {
          credential: response.data.errors[0],
        };
      }

      yield put({
        type: ERROR_VERIFY_MOBILE_NUMBER,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}

//END:Phone Veriffication

//  START :Phone Veriffication Otp
export function* PhoneVerificationOtpSagas() {
  while (true) {
    const { mobile } = yield take(START_VERIFY_OTP);
    const response = yield call(PhoneVerificationOtp, mobile);
    const { message } = response;
    try {
      if (response.status) {
        yield put({
          type: SUCCESS_VERIFY_OTP,
          message,
        });
      } else {
        yield put({
          type: ERROR_VERIFY_OTP,
          message: message,
        });
      }
    } catch (e) {
      yield put({
        type: ERROR_CHANGE_PASSWORD,
        message: message || "Something went wrong, please try again",
      });
    }
  }
}

//  END :Phone Veriffication Otp

export function* getUserLoggedInDetailSaga() {
  while (true) {
    yield take(FETCH_USER_LOGGED_IN_DETAIL);
    const response = yield call(userData);
    // response.data.reward_points = 3000;
    // response.data.reward_type = "silver";
    if (response.status) {
      yield put({
        type: GET_USER_LOGGED_IN_DETAIL,
        payload: response.data,
      });
    } else {
      yield put({
        type: USER_LOGGED_IN_DETAIL_ERROR,
      });
    }
  }
}

export function* logoutSaga() {
  while (true) {
    yield take(LOGOUT);
    const response = yield call(logout);
    const { message } = response;
    if (response.status) {
      yield call(API.removeTokenFromLocalStorage);
      window.location.href = "/";
    } else {
      yield put({
        type: ERROR_LOGOUT,
        message: message || "Something went wrong, please try again",
      });
    }
  }
}

//END : Logout
