import { call, put, take } from "redux-saga/effects";
import {
    START_SAVE_PROFILE,
    SUCCESS_SAVE_PROFILE,
    ERROR_SAVE_PROFILE,
    FETCH_PROFILE_DETAIL,
    GET_PROFILE_DETAIL,
    ERROR_PROFILE_DETAIL,
    START_GALLARY_PHOTO_UPLOAD,
    SUCCESS_GALLARY_PHOTO_UPLOAD,
    ERROR_GALLARY_PHOTO_UPLOAD,
    FETCH_COUNTRY_LIST,
    GET_COUNTRY_LIST,
    ERROR_COUNTRY_LIST
} from '../actions/ProfileActions';
import {
    profileSave,
    profileDetails,
    GallearyPhotoUpload,
    countryCodeList
} from '../../api/ProfileApi';

//START: Profle 
export function* profileSagas() {
    while (true) {
        const { formData } = yield take(START_SAVE_PROFILE);
        const response = yield call(profileSave, formData);
        const { message } = response;
        if (response.status) {
            yield put({
                type: SUCCESS_SAVE_PROFILE,
                message,
                profileDetail: response.data
            })
        }
        else {
            yield put({
                type: ERROR_SAVE_PROFILE,
                message: message || 'Something went wrong, please try again',
                errorBag: response.data.error || {}
            })
        }
    }
}
//END:Profile

//START:Profile data details
export function* getProfileDetailSaga() {
    while (true) {
        yield take(FETCH_PROFILE_DETAIL);
        const response = yield call(profileDetails);
        if (response.status) {
            yield put({
                type: GET_PROFILE_DETAIL,
                payload: response.data
            });
        } else {
            yield put({
                type: ERROR_PROFILE_DETAIL
            });
        }
    }
}
//END:Profile data details

//START: Gallary Photo Upload
export function* gallaryPhotoUploadSagas() {
    while (true) {
        const { formData } = yield take(START_GALLARY_PHOTO_UPLOAD);
        const response = yield call(GallearyPhotoUpload, formData);
        const { message } = response;
        if (response.status) {
            yield put({
                type: SUCCESS_GALLARY_PHOTO_UPLOAD,
                message,
                photoGalleryDetail: response.data
            })
        }
        else {
            yield put({
                type: ERROR_GALLARY_PHOTO_UPLOAD,
                message: message || 'Something went wrong, please try again',
            })
        }
    }
}
//END:Gallary Photo Upload

//START:country Code List
export function* countryCodeListSagas() {
    while (true) {
        yield take(FETCH_COUNTRY_LIST);
        const response = yield call(countryCodeList);
        if (response.status) {
            yield put({
                type: GET_COUNTRY_LIST,
                payload: response.data
            });
        } else {
            yield put({
                type: ERROR_COUNTRY_LIST
            });
        }
    }
}
//END:country Code List