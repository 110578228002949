import { call, put, take } from "redux-saga/effects";
import {
  START_COLOR_THEME,
  SUCCESS_COLOR_THEME,
  ERROR_COLOR_THEME,
  FETCH_COLOR_THEME,
  GET_COLOR_THEME_DETAIL,
  ERROR_COLOR_THEME_DETAIL,
  SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
  FETCH_COLOR_USING_ADMIN_THEME,
  GET_COLOR_USING_ADMIN_THEME,
  ERROR_COLOR_USING_ADMIN_THEME,
} from "../actions/ColorThemeActions";
import {
  saveColorTheme,
  getColorTheme,
  getColorUsingAdminTheme,
} from "../../api/colorThemeApi";

//START: Edit Color Theme
export function* editColorThemeSagas() {
  while (true) {
    const {
      red_color,
      green_color,
      blue_color,
      font_blue_color,
      font_green_color,
      font_red_color,
      theme_mode,
    } = yield take(START_COLOR_THEME);
    const response = yield call(
      saveColorTheme,
      red_color,
      green_color,
      blue_color,
      font_blue_color,
      font_green_color,
      font_red_color,
      theme_mode
    );
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_COLOR_THEME,
        message,
        payload: {
          red_color,
          green_color,
          blue_color,
          font_blue_color,
          font_green_color,
          font_red_color,
          theme_mode,
        },
      });

      yield put({
        type: SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
        _theme: {
          red_color,
          green_color,
          blue_color,
          font_blue_color,
          font_green_color,
          font_red_color,
          theme_mode,
        },
      });

      document.body.style.backgroundColor =
        theme_mode === "dark" ? "black" : "white";
    } else {
      yield put({
        type: ERROR_COLOR_THEME,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}

//END: Color Theme

export function* getColorThemeDetailSaga() {
  while (true) {
    yield take(FETCH_COLOR_THEME);
    const response = yield call(getColorTheme);
    if (response.status) {
      yield put({
        type: GET_COLOR_THEME_DETAIL,
        payload: response.data,
      });

      yield put({
        type: SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
        _theme: response.data,
      });

      document.body.style.backgroundColor =
        response.data?.theme_mode === "dark" ? "black" : "white";
    } else {
      yield put({
        type: ERROR_COLOR_THEME_DETAIL,
      });
    }
  }
}

//Get Color Theme Using Admin
export function* getColorThemeUsingAdminDetailSaga() {
  while (true) {
    yield take(FETCH_COLOR_USING_ADMIN_THEME);
    const response = yield call(getColorUsingAdminTheme);
    if (response.status) {
      yield put({
        type: GET_COLOR_USING_ADMIN_THEME,
        payload: response.data,
      });

      yield put({
        type: SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
        _theme: response.data,
      });

      document.body.style.backgroundColor =
        response.data?.theme_mode === "dark" ? "black" : "white";
    } else {
      yield put({
        type: ERROR_COLOR_USING_ADMIN_THEME,
      });
    }
  }
}
