import {
    START_DELETE_PHOTO,
    SUCCESS_DELETE_PHOTO,
    ERROR_DELETE_PHOTO,
    EMPTY_MESSAGE,
} from '../actions/ProfileActions';

const initialState = {
    deletePhotoIsLoading: false,
    deletePhotoErrorMessage: "",
    deletePhotoSuccessMessage: ""
};


const GalleyPhotoDeleteReducer = (prevState = initialState, action) => {
    switch (action.type) {
        //START: Gallery Photo Delete 
        case START_DELETE_PHOTO:
            return {
                ...prevState,
                deletePhotoIsLoading: true,
                deletePhotoErrorMessage: "",
            };
        case SUCCESS_DELETE_PHOTO:
            return {
                ...prevState,
                deletePhotoIsLoading: false,
                deletePhotoSuccessMessage: action.message,
            };
        case ERROR_DELETE_PHOTO:
            return {
                ...prevState,
                deletePhotoIsLoading: false,
                deletePhotoErrorMessage: action.message,
            };
        //END: Gallery Photo Delete 

        case EMPTY_MESSAGE: {
            return {
                ...prevState,
                [action.key]: initialState[action.key],
            };
        }
        default:
            return prevState
    }
};


export default GalleyPhotoDeleteReducer;