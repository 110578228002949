import { call, put, take } from "redux-saga/effects";
import {
    START_SEARCH,
    SUCCESS_SEARCH,
    ERROR_SEARCH,
} from '../actions/SearchColleaguesActions';
import {
    searchColleaguesAndTeamAdd
} from '../../api/SearchColleagueApi';


//START: Search Colleagues

export function* searchColleaguesAndTeamDetailSaga() {
    while (true) {
        const { searchTerm} = yield take(START_SEARCH);
        const response = yield call(searchColleaguesAndTeamAdd,searchTerm);
        if (response.status) {
            yield put({
                type: SUCCESS_SEARCH,
                colleagueData: response.data
            });
        } else {
            yield put({
                type: ERROR_SEARCH,
                colleagueData : []
            });
        }
    }
}

//END: Search Colleagues