//Colleagues & team add
export const START_COLLEAGUES_TEAM = "START_COLLEAGUES_TEAM"
export const SUCCESS_COLLEAGUES_TEAM = "SUCCESS_COLLEAGUES_TEAM"
export const ERROR_COLLEAGUES_TEAM = "ERROR_COLLEAGUES_TEAM"

//Colleagues & teamGet
export const FETCH_COLLEAGUES_TEAM = "FETCH_COLLEAGUES_TEAM"
export const GET_COLLEAGUES_TEAM_DETAIL = "GET_COLLEAGUES_TEAM_DETAIL"
export const ERROR_COLLEAGUES_TEAM_DETAIL = "ERROR_COLLEAGUES_TEAM_DETAIL"

//Search Colleagues

export const START_SEARCH = "START_SEARCH"
export const SUCCESS_SEARCH = "SUCCESS_SEARCH"
export const ERROR_SEARCH = "ERROR_SEARCH"

//Colleagues list
export const FETCH_VISTOR_COLLEAGUE_TEAM = "FETCH_VISTOR_COLLEAGUE_TEAM"
export const GET_VISTOR_COLLEAGUE_TEAM = "GET_VISTOR_COLLEAGUE_TEAM"
export const ERROR_VISTOR_COLLEAGUE_TEAM = "ERROR_VISTOR_COLLEAGUE_TEAM"

//Empty 
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";


//Delete Colleague
export const START_DELETE_COLLEAGUES = "START_DELETE_COLLEAGUES";
export const SUCCESS_DELETE_COLLEAGUES = "SUCCESS_DELETE_COLLEAGUES";
export const ERROR_DELETE_COLLEAGUES = "ERROR_DELETE_COLLEAGUES";

