import {
  START_PREMIUM_PLANS,
  SUCCESS_PREMIUM_PLANS,
  ERROR_PREMIUM_PLANS,
  EMPTY_MESSAGE,
} from "../actions/PremiumPlansActions";

const initialState = {
  isPremiumPlansDetail: false,
  premiumPlansDetail: {},
  isLoadingPremiumPlan: false,
  isError: false,
  premiumPlanSuccessMessage: "",
  errorMessage: "",
  errorBag: {},
  enterpriseOfferSuccess: "",
  isLoadingEnterpriseOffer: false,
  enterpriseOffererrorMessage: "",
  subscriptionErrorBag: {},
};

const SubscriptionPlanReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Premium plans subscribe
    case START_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: true,
        isError: false,
        errorMessage: "",
      };
    }
    case SUCCESS_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: false,
        premiumPlanSuccessMessage: action.message,
      };
    }
    case ERROR_PREMIUM_PLANS: {
      return {
        ...prevState,
        isLoadingPremiumPlan: false,
        isError: true,
        errorMessage: action.message,
        errorBag: action.errorBag,
      };
    }
    //END:Premium plans subscribe
    //START: Error Empty Message
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //END: Error Empty Message
    default:
      return prevState;
  }
};

export default SubscriptionPlanReducer;
