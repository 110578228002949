import API from "../api";

//Color Theme  API

export function saveColorTheme(
  red_color,
  green_color,
  blue_color,
  font_blue_color,
  font_green_color,
  font_red_color,
  theme_mode
) {
  return API.post("/edit_theme_color", {
    red_color,
    green_color,
    blue_color,
    font_blue_color,
    font_green_color,
    font_red_color,
    theme_mode,
  });
}

//Color Theme Listing API

export function getColorTheme() {
  return API.get("/get_theme_color");
}

//Get Color Based on Admin Change
export function getColorUsingAdminTheme() {
  return API.get("/get_theme_color");
}
