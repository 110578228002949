import { call, put, take } from "redux-saga/effects";
import {
  GET_VCARD_DOWNLOAD_START,
  GET_VCARD_DOWNLOAD_SUCCESS,
  GET_VCARD_DOWNLOAD_ERROR,
} from "../actions/VcardDownloadActions";
import { getVcardDownload } from "../../api/vcardApi";

export function* VcardDownloadSagas() {
  while (true) {
    const { userId } = yield take(GET_VCARD_DOWNLOAD_START);
    const response = yield call(getVcardDownload, userId);
    if (response.status) {
      yield put({
        type: GET_VCARD_DOWNLOAD_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: GET_VCARD_DOWNLOAD_ERROR,
      });
    }
  }
}
