import { useEffect, useState } from "react";
import { themeLight, themeDark } from "../theme";
import { useSelector } from "react-redux";

let primaryLightCore = {
  body: "#FFFFFF",
  light: "#FFE084",
  main: "#F6CB04",
  dark: "#B29747",
  fontColor: "#B29747",
  linkColor: "#ffcb03",
  contrastText: "#000000",
  fontFamily: "Visby Round CF",
  advertise: {
    background: {
      default: "#222222",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  textBox: {
    background: {
      textColor: "#f4f4f4",
    },
  },
};
let primaryDarkCore = {
  body: "#000000",
  light: "#FFE084",
  main: "#F6CB04",
  dark: "#B29747",
  fontColor: "#B29747",
  linkColor: "#ffcb03",
  contrastText: "#FFFFFF",
  advertise: {
    background: {
      default: "#222222",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  textBox: {
    background: {
      textColor: "#696969",
    },
  },
};

export const useTheme = (themeForBussinessInfo = false) => {
  const { _theme } = useSelector((state) =>
    !themeForBussinessInfo ? state.theme : state.businessInfo
  );
  const { userDetail } = useSelector((state) => state.user);
  const { businessInfoDetail } = useSelector((state) => state.businessInfo);

  const primaryLight = {
    ...primaryLightCore,
    main:
      businessInfoDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.red_color},${_theme?.green_color},${_theme?.blue_color})`
        : businessInfoDetail?.type_of_account === "Standard"
        ? primaryLightCore.main
        : userDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.red_color},${_theme?.green_color},${_theme?.blue_color})`
        : primaryLightCore.main,
    fontColor:
      businessInfoDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.font_red_color},${_theme?.font_green_color},${_theme?.font_blue_color})`
        : businessInfoDetail?.type_of_account === "Standard"
        ? primaryLightCore.main
        : userDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.font_red_color},${_theme?.font_green_color},${_theme?.font_blue_color})`
        : primaryLightCore.main,
  };
  const primaryDark = {
    ...primaryDarkCore,
    main:
      businessInfoDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.red_color},${_theme?.green_color},${_theme?.blue_color})`
        : businessInfoDetail?.type_of_account === "Standard"
        ? primaryDarkCore.main
        : userDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.red_color},${_theme?.green_color},${_theme?.blue_color})`
        : primaryDarkCore.main,
    fontColor:
      businessInfoDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.font_red_color},${_theme?.font_green_color},${_theme?.font_blue_color})`
        : businessInfoDetail?.type_of_account === "Standard"
        ? primaryDarkCore.main
        : userDetail?.type_of_account === "Premium"
        ? `rgb(${_theme?.font_red_color},${_theme?.font_green_color},${_theme?.font_blue_color})`
        : primaryDarkCore.main,
  };
  const [theme, setTheme] = useState(themeLight(primaryLight));
  const [themeLoaded, setThemeLoaded] = useState(false);

  const selectThemeBasedOnData = (localTheme) => {
    let currentTheme = themeLight(primaryLight);
    if (localTheme && localTheme.theme_mode === "dark") {
      //if (localTheme?.theme_mode === 'dark') {
      currentTheme = themeDark(primaryDark);
    }
    setTheme(currentTheme);
  };
  useEffect(() => {
    // if (_theme) {
      selectThemeBasedOnData(_theme);
      setThemeLoaded(true);
    // }
  }, [_theme]);
  return { theme, themeLoaded };
};
