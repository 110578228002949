import API from "../api";


//Feedback  API
export function feedbackSave(rate, typeFeedback, feedbackMessage) {
    return API.post("/feedback",
        {
            rate,
            type : typeFeedback,
            feedback_message : feedbackMessage
        });
}