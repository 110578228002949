import {
    START_SAVE_FEEDBACK,
    SUCCESS_SAVE_FEEDBACK,
    ERROR_SAVE_FEEDBACK,
    EMPTY_MESSAGE
} from '../actions/FeedbackActions';

const initialState = {
    isLoading: false,
    isError: false,
    feedbackSuccessMessage: '',
    feedbackErrorMessage: '',
    errorsBag: {},
};

const FeedbackReducer = (prevState = initialState, action) => {
    switch (action.type) {
        case START_SAVE_FEEDBACK: {
            return {
                ...prevState,
                isLoading: true,
                isError: false,
            }
        }
        case SUCCESS_SAVE_FEEDBACK: {
            return {
                ...prevState,
                isLoading: false,
                isError: false,
                feedbackSuccessMessage: action.message,
            }
        }
        case ERROR_SAVE_FEEDBACK: {
            return {
                ...prevState,
                isLoading: false,
                isError: true,
                feedbackErrorMessage: action.message,
                errorsBag: action.errorsBag,
            }
        }
        //END :Forgot Password Case
        case EMPTY_MESSAGE: {
            return {
                ...prevState,
                [action.key]: initialState[action.key],
            };
        }
        default:
            return prevState

    }
};


export default FeedbackReducer;