import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
    animationDuration: "550ms",
    color: theme.palette.primary.main,
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    height: "auto",
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 2,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
  },
  backgroundOverlay: {
    backgroundColor: "rgba(0,0,0,.80)",
  },
}));

export default function PageLoading(props) {
  const { showMessage = true } = props;
  const classes = useStyles();
  const { userDetail } = useSelector((state) => state.user);
  const { _theme } = useSelector((state) => state.theme);
  return (
    <div
      className={`${classes.loader} ${
        showMessage ? `${classes.backgroundOverlay}` : ""
      }`}
    >
      <CircularProgress
        className={classes.progress}
        disableShrink
        thickness={5}
        style={{
          color:
            userDetail?.type_of_account === "Premium" &&
            _theme?.red_color &&
            _theme?.green_color &&
            _theme?.blue_color
              ? `rgb(${_theme?.red_color}, ${_theme?.green_color}, ${_theme?.blue_color})`
              : userDetail?.type_of_account === "Standard" &&
                _theme?.red_color &&
                _theme?.green_color &&
                _theme?.blue_color
              ? `rgb(${_theme?.red_color}, ${_theme?.green_color}, ${_theme?.blue_color})`
              : `rgb(${_theme?.red_color}, ${_theme?.green_color}, ${_theme?.blue_color})`,
        }}
      />
      {showMessage && (
        <span style={{ color: "white" }}>Fetching data, please wait...</span>
      )}
    </div>
  );
}
