import API from "../api";

//Setting  API
export function saveSetting(
  typeOfAccount,
  name,
  email,
  mobile,
  subs_expiration_date,
  joined_groups,
  country_code
) {
  return API.post("/setting", {
    type_of_account: typeOfAccount,
    name,
    email,
    mobile,
    subs_expiration_date,
    joined_groups,
    country_code,
  });
}

//Setting Data API
export function settingDataDetails() {
  return API.get("/setting-details");
}
