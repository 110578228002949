export default Object.freeze({
  CARDS: {
    gold: {
      image: "Asset 22@4x.png",
      color: " #F8E222",
    },
    silver: {
      image: "Asset 21@4x.png",
      color: "#616161", 
    },
    bronze: {
      image: "Asset 20@4x.png",
      color: " #E88A4B",
    },
  },
});
