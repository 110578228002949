//Color Theme Edit
export const START_COLOR_THEME = "START_COLOR_THEME"
export const SUCCESS_COLOR_THEME = "SUCCESS_COLOR_THEME"
export const ERROR_COLOR_THEME = "ERROR_COLOR_THEME"

// Color Theme Get
export const FETCH_COLOR_THEME = "FETCH_COLOR_THEME"
export const GET_COLOR_THEME_DETAIL = "GET_COLOR_THEME_DETAIL"
export const ERROR_COLOR_THEME_DETAIL = "ERROR_COLOR_THEME_DETAIL"

export const SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE = "SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE"

//Empty
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";

// Color Theme Get Using Admin
export const FETCH_COLOR_USING_ADMIN_THEME = "FETCH_COLOR_USING_ADMIN_THEME"
export const GET_COLOR_USING_ADMIN_THEME = "GET_COLOR_USING_ADMIN_THEME"
export const ERROR_COLOR_USING_ADMIN_THEME= "ERROR_COLOR_USING_ADMIN_THEME"