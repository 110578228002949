import {
  FETCH_BUSINESS_INFO_DETAIL,
  GET_BUSINESS_INFO_DETAIL,
  ERROR_BUSINESS_INFO_DETAIL,
} from "../actions/BusinessInfoActions";

const initialState = {
  isBusinessInfoDetail: false,
  isLoading: false,
  businessInfoDetail: {},
  isValidProfile: true,
  _theme: {},
};

const BusinessInfoReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Get Business Info details case
    case FETCH_BUSINESS_INFO_DETAIL: {
      return {
        ...prevState,
        isBusinessInfoDetail: true,
        isLoading: true,
      };
    }
    case GET_BUSINESS_INFO_DETAIL:
      let theme = {};
      if (action.payload.themecolor) {
        theme = action.payload.themecolor;
      }

      return {
        ...prevState,
        isLoggedIn: true,
        isLoading: false,
        isBusinessInfoDetail: false,
        businessInfoDetail: action.payload,
        _theme: theme,
      };
    case ERROR_BUSINESS_INFO_DETAIL:
      return {
        ...prevState,
        businessInfoDetail: {},
        isBusinessInfoDetail: false,
        isValidProfile: false,
        isLoading: false,
      };
    //END: Get Business Info details case

    default:
      return prevState;
  }
};

export default BusinessInfoReducer;
