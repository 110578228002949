import API from "../api";

//Login API
export function login(email, password) {
    return API.post("/login", {
        email,
        password,
    })
        .then((response) => {
            if (response.status) {
                return response;
            } else if (response.message) {
                return {
                    success: false,
                    message: response.data.message,
                    errors: response.data.errors,
                };
            }
            return { status: false, message: "Ill formed response!" };
        })
        .catch((error) => {
            return {
                status: false,
                message: error.message || "Something went wrong! Try again later",
            };
        });
}

//Forgot Password API
export function forgotPasswordRequest(email) {
    return API.post("/forgot-password", { email });
}

//Change Password API
export function changePassword(currentPassword, newPassword, confirmPassword) {
    return API.post("/change_password",
        {
            current_password: currentPassword,
            new_password: newPassword,
            new_confirm_password: confirmPassword
        });
}

//User Data API
export function userData() {
    return API.get("/user");
}

//Logout API
export function logout() {
    return API.get("/logout")
        .then((response) => {
            if (response.status) {
                return { status: true };
            } else if (response.unauthenticated) {
                return { status: false, message: "Logging out....." };
            }
            //return { status: false, message: "Ill formed response!" };
        })
        .catch((error) => {
            return {
                status: false,
                message: error.message || "Something went wrong! Try again later",
            };
        });
}

//Phone Verifiaction Create Otp
export function PhoneVerificationOtp(mobile) {
    return API.post("/create_otp", { mobile });
}

//Phone Verifiaction 
export function PhoneVerificationLogin(mobile, otp) {
    return API.post("/login_mobile",
        {
            mobile,
            otp
        });
}