import {
    START_SEARCH,
    SUCCESS_SEARCH,
    ERROR_SEARCH,
} from '../actions/SearchColleaguesActions';

const initialState = {
    searchIsLoading: false,
    searchErrorMessage: '',
    colleagueData: [],
};


const SearchColleaguesAndTeamReducer = (prevState = initialState, action) => {
    switch (action.type) {
        //START: Search Colleagues & Team Case
        case START_SEARCH:
            return {
                ...prevState,
                searchIsLoading: true,
                colleagueData: [],
                searchErrorMessage: "",
            };
        case SUCCESS_SEARCH:
            return {
                ...prevState,
                searchIsLoading: false,
                colleagueData: action.colleagueData,
            };
        case ERROR_SEARCH:
            return {
                ...prevState,
                searchIsLoading: false,
                colleagueData: [],
                searchErrorMessage: action.message,
            };
        //END:Search Colleagues & Team Case
        default:
            return prevState
    }
};


export default SearchColleaguesAndTeamReducer;