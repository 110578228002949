import {
  GET_ANALYTICS_DETAILS_START,
  GET_ANALYTICS_DETAILS_SUCCESS,
  GET_ANALYTICS_DETAILS_ERROR,
  GET_MONTH_COUNTS_START,
  GET_MONTH_COUNTS_SUCCESS,
  GET_MONTH_COUNTS_ERROR,
  GET_DAILY_COUNTS_START,
  GET_DAILY_COUNTS_SUCCESS,
  GET_DAILY_COUNTS_ERROR,
  EMPTY_MESSAGE,
} from "../actions/AnalyticsActions";

const initialState = {
  isLoadingCounters: false,
  analyticsSuccessMessage: "",
  analyticsErrorMessage: "",
  errorBag: {},
  analyticDetails: {},
  analyticMonthCountsDetails: {},
  analyticDailyCountsDetails: {},
  isLoadingDaily: false,
  isLoadingMonthly: false,
  isLoadingAllTimeTabs: false,
  isLoadingStatistics: false,
};

const AnalyticsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    // START: Analytics Details
    case GET_ANALYTICS_DETAILS_START: {
      return {
        ...prevState,
        isLoadingCounters: true,
      };
    }
    case GET_ANALYTICS_DETAILS_SUCCESS:
      return {
        ...prevState,
        isLoadingCounters: false,
        analyticDetails: action.payload,
      };
    case GET_ANALYTICS_DETAILS_ERROR:
      return {
        ...prevState,
        analyticDetails: {},
        isLoadingCounters: false,
      };
    // END: Analytics Details

    // START: Analytics Month Counts
    case GET_MONTH_COUNTS_START: {
      return {
        ...prevState,
        isLoadingMonthly: true,
      };
    }
    case GET_MONTH_COUNTS_SUCCESS:
      return {
        ...prevState,
        isLoadingMonthly: false,
        analyticMonthCountsDetails: action.payload,
      };
    case GET_MONTH_COUNTS_ERROR:
      return {
        ...prevState,
        analyticMonthCountsDetails: {},
        isLoadingMonthly: false,
      };
    // END: Analytics Month Counts

    // START: Analytics Daily Counts
    case GET_DAILY_COUNTS_START: {
      return {
        ...prevState,
        isLoadingDaily: true,
      };
    }
    case GET_DAILY_COUNTS_SUCCESS:
      return {
        ...prevState,
        isLoadingDaily: false,
        analyticDailyCountsDetails: action.payload,
      };
    case GET_DAILY_COUNTS_ERROR:
      return {
        ...prevState,
        analyticDailyCountsDetails: {},
        isLoadingDaily: false,
      };
    // END: Analytics Daily Counts

    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    default:
      return prevState;
  }
};

export default AnalyticsReducer;
