import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import SettingReducer from "./SettingReducer";
import FeedbackReducer from "./FeedbackReducer";
import ColorThemeReducer from "./ColorThemeReducer";
import PremiumplansReducer from "./PremiumPlansReducer";
import RewardsReducer from "./RewardPointsReducer";
import ColleaguesAndTeamReducer from "./ColleaguesAndTeamReducer";
import ProfileReducer from "./ProfileReducer";
import SearchColleaguesAndTeamReducer from "./SearchColleaguesReducer";
import BusinessInfoReducer from "./BusinessInfoReducer";
import GalleyPhotoDeleteReducer from "./GalleryPhotoDeleteReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import SubscriptionPlanReducer from "./SubscriptionPlanReducer";
import VcardReducer from "./VcardDownloadReducer";

export default combineReducers({
  user: UserReducer,
  setting: SettingReducer,
  feedback: FeedbackReducer,
  theme: ColorThemeReducer,
  premiumPlans: PremiumplansReducer,
  rewards: RewardsReducer,
  ColleaguesAndTeam: ColleaguesAndTeamReducer,
  search: SearchColleaguesAndTeamReducer,
  profile: ProfileReducer,
  businessInfo: BusinessInfoReducer,
  galleryPhotoDelete: GalleyPhotoDeleteReducer,
  analyticPage: AnalyticsReducer,
  subscriptionPlan: SubscriptionPlanReducer,
  vcardData: VcardReducer,
});
