// Premium Plans Listing
export const FETCH_PREMIUM_PLANS = "FETCH_PREMIUM_PLANS";
export const GET_PREMIUM_PLANS_DETAIL = "GET_PREMIUM_PLANS_DETAIL";
export const ERROR_PREMIUM_PLANS_DETAIL = "ERROR_PREMIUM_PLANS_DETAIL";

// Premium Plans Subscribe
export const START_PREMIUM_PLANS = "START_PREMIUM_PLANS";
export const SUCCESS_PREMIUM_PLANS = "SUCCESS_PREMIUM_PLANS";
export const ERROR_PREMIUM_PLANS = "ERROR_PREMIUM_PLANS";

//Empty
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";

// Enterprise offer
export const START_ENTERPRISE_OFFER = "START_ENTERPRISE_OFFER";
export const SUCCESS_ENTERPRISE_OFFER = "SUCCESS_ENTERPRISE_OFFER";
export const ERROR_ENTERPRISE_OFFER = "ERROR_ENTERPRISE_OFFER";


export const FETCH_PREMIUM_PLANS_VIEW = "FETCH_PREMIUM_PLANS_VIEW";
export const GET_PREMIUM_PLANS_DETAIL_VIEW = "GET_PREMIUM_PLANS_DETAIL_VIEW";
export const ERROR_PREMIUM_PLANS_DETAIL_VIEW = "ERROR_PREMIUM_PLANS_DETAIL_VIEW";