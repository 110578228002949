export default Object.freeze({
  LOGIN: {
    name: "Login",
    route: "/",
  },
  PHONE_VERIFICATION: {
    name: "Phone Verification",
    route: "/phone-verification",
  },
  CHANGE_PASSWORD: {
    name: "Change Password",
    route: "/change-password",
  },
  FEEDBACK: {
    name: "Feedback",
    route: "/feedback",
    subRoutes: {
      SUCCESS: {
        name: "Feedback Success",
        route: "/feedback-success",
      },
    },
  },
  SETTING: {
    name: "Setting",
    route: "/setting",
  },
  CLAIM_SUCCESS: {
    name: "ClaimSuccess",
    route: "/claim-success",
  },
  ADVERTISEMENT: {
    name: "Advertisement",
    route: "/advertisement/:advert_no(1|2|3|4)",
  },
  THEME_COLOR: {
    name: "ThemeColor",
    route: "/theme-color",
  },
  REWARD: {
    name: "RewardsAndBenefits",
    route: "/rewards-and-benefits",
  },
  COLLEAGUE_TEAM_ADD: {
    name: "ColleaguesTeamAdd",
    route: "/colleagues-team-add",
  },
  TEAM_COLLEAGUE: {
    name: "TeamAndColleagues",
    route: "/team-colleagues/:id",
  },
  PREMIUM_PLANS: {
    name: "PremiumPlans",
    route: "/premium-plans",
  },
  BUSINESS_INFO: {
    name: "BusinessInfo",
    route: "/business-info/:id",
  },
  PROFILE: {
    name: "Profile",
    route: "/profile",
  },
  ANALYTICS: {
    name: "Analytics",
    route: "/analytics",
  },
  NOT_FOUND: {
    name: "NotFound",
    route: "/404",
  },
  INTERNAL_SERVER_ERROR: {
    name: "InternalSeverError",
    route: "/maintenance",
  },

  PREMIUM_PLANS_TO_ANOTHER_PAGE: {
    name: "PremiumPlans",
    route: "/premium-plans/user/:userId",
  },
});
