import {createMuiTheme} from "@material-ui/core";


const themeLight = (primaryObject) => createMuiTheme({
    palette : {
        primary: primaryObject,
        secondary: {
            light: "#769B5D",
            main: "#548235",
            dark: "#3A5B25",
            contrastText: "#fff",
            fontColor:"#3A5B25"
        },
    },
    props : {
        MuiButton : {
            //variant : "contained"
        }
    }
});


const themeDark = (primaryObject) => createMuiTheme({
    palette: {
        primary: primaryObject
    },
});
export {
    themeLight,
    themeDark
}