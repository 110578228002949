import {
  GET_VCARD_DOWNLOAD_START,
  GET_VCARD_DOWNLOAD_SUCCESS,
  GET_VCARD_DOWNLOAD_ERROR,
} from "../actions/VcardDownloadActions";

const initialState = {
  isLoading: false,
  vcardDownloadDetails: {},
  vcardDownloadSuccessMessage: "",
  vcardDownloadErrorMessage: "",
};

const VcardReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_VCARD_DOWNLOAD_START: {
      return {
        ...prevState,
        isLoading: true,
      };
    }
    case GET_VCARD_DOWNLOAD_SUCCESS:
      return {
        ...prevState,
        isLoading: false,
        vcardDownloadDetails: action.payload,
        vcardDownloadSuccessMessage: action.message,
      };
    case GET_VCARD_DOWNLOAD_ERROR:
      return {
        ...prevState,
        vcardDownloadDetails: {},
        isLoading: false,
        vcardDownloadErrorMessage: action.message,
      };
    default:
      return prevState;
  }
};

export default VcardReducer;
