import {
  START_COLOR_THEME,
  SUCCESS_COLOR_THEME,
  ERROR_COLOR_THEME,
  FETCH_COLOR_THEME,
  GET_COLOR_THEME_DETAIL,
  ERROR_COLOR_THEME_DETAIL,
  EMPTY_MESSAGE,
  SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE,
  FETCH_COLOR_USING_ADMIN_THEME,
  GET_COLOR_USING_ADMIN_THEME,
  ERROR_COLOR_USING_ADMIN_THEME,
} from "../actions/ColorThemeActions";
import CF from "../../utils/CommonFunction";
//import {useTheme} from "../../hooks/useTheme";
const initialState = {
  isLoading: false,
  isError: false,
  colorThemeSuccessMessage: "",
  colorThemeErrorMessage: "",
  isColorThemeDetail: false,
  colorThemeDetail: {},
  errorBag: {},
};

const ColorThemeReducer = (prevState = initialState, action) => {
  //const {setThemeData} = useTheme();

  switch (action.type) {
    //START: Edit Color Theme Case
    case START_COLOR_THEME: {
      return {
        ...prevState,
        isLoading: true,
        isError: false,
      };
    }
    case SUCCESS_COLOR_THEME: {
      //Set theme in localstorage
      CF.setLocalStorage("_THEME", { ...action.payload });
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        colorThemeSuccessMessage: action.message,
      };
    }
    case ERROR_COLOR_THEME: {
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        colorThemeErrorMessage: action.message,
        errorBag: action.errorBag,
      };
    }
    //END: Edit Color Theme Case
    //START: Get Color Theme details case
    case FETCH_COLOR_THEME: {
      return {
        ...prevState,
        isColorThemeDetail: true,
      };
    }
    case GET_COLOR_THEME_DETAIL:
      //Set theme in localstorage
      CF.setLocalStorage("_THEME", { ...action.payload });
      return {
        ...prevState,
        isColorThemeDetail: false,
        colorThemeDetail: action.payload,
      };
    case ERROR_COLOR_THEME_DETAIL:
      return {
        ...prevState,
        colorThemeDetail: {},
        isColorThemeDetail: false,
      };
    //END: Get Color Theme  details case
    //Empty message
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    case SET_THEME_IN_REDUX_FROM_LOCAL_STORAGE: {
      return {
        ...prevState,
        _theme: action._theme,
      };
    }
    //Fetch Color theme Using Admin
    case FETCH_COLOR_USING_ADMIN_THEME: {
      return {
        ...prevState,
        isColorThemeDetail: true,
      };
    }
    case GET_COLOR_USING_ADMIN_THEME:
      //Set theme in localstorage
      CF.setLocalStorage("_THEME", { ...action.payload });
      return {
        ...prevState,
        isColorThemeDetail: false,
        colorThemeDetail: action.payload,
      };
    case ERROR_COLOR_USING_ADMIN_THEME:
    default:
      return prevState;
  }
};

export default ColorThemeReducer;
