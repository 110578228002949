//Setting Save 
export const START_SAVE_SETTING = "START_SAVE_SETTING"
export const SUCCESS_SAVE_SETTING = "SUCCESS_SAVE_SETTING"
export const ERROR_SAVE_SETTING = "ERROR_SAVE_SETTING"

//Setting Details
export const FETCH_SETTING_DETAIL = "FETCH_SETTING_DETAIL"
export const GET_SETTING_DETAIL = "GET_SETTING_DETAIL"
export const ERROR_SETTING_DETAIL = "ERROR_SETTING_DETAIL"


//Empty Message
export const EMPTY_MESSAGE = "EMPTY_MESSAGE";