import { all } from "redux-saga/effects";
import {
  loginSaga,
  forgotPasswordSaga,
  changePasswordSaga,
  getUserLoggedInDetailSaga,
  logoutSaga,
  PhoneVerificationSaga,
  PhoneVerificationOtpSagas,
} from "./UserSagas";
import { settingSagas, getSettingUserDetailSaga } from "./SettingSagas";
import { feedbackSagas } from "./FeedbackSagas";
import {
  editColorThemeSagas,
  getColorThemeDetailSaga,
  getColorThemeUsingAdminDetailSaga,
} from "./ColorThemeSagas";
import {
  profileSagas,
  getProfileDetailSaga,
  gallaryPhotoUploadSagas,
  countryCodeListSagas,
} from "./ProfileSagas";
import {
  getColleaguesAndTeamDetailSaga,
  addColleaguesAndTeamSagas,
  getColleaguesAndTeamDetailSagaList,
  deleteColleaguesSagas,
} from "./ColleaguesAndTeamSagas";
import { getRewardSaga, RewardListingSaga } from "./RewardPointsSagas";
import {
  getPremiumPlanSaga,
  epnterpriseOfferSagas,
  preminPlanViewSaga,
} from "./PremiumPlansSagas";
import { searchColleaguesAndTeamDetailSaga } from "./SearchColleagusSagas";
import { BusinessInfoSagas } from "./BusinessInfoSagas";
import { GalleyPhotoDeleteSagas } from "./GalleryPhotoDeleteSagas";
import {
  AnalyticsDetailsSagas,
  AnalyticsDailyCountsSagas,
  AnalyticsMonthCountsSagas,
} from "./AnalyticsSagas";
import { subscriptionPlanSagas } from "./subscriptionPlanSagas";
import { VcardDownloadSagas } from "./VcardDownloadSagas";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    getUserLoggedInDetailSaga(),
    settingSagas(),
    logoutSaga(),
    getSettingUserDetailSaga(),
    feedbackSagas(),
    editColorThemeSagas(),
    getColorThemeDetailSaga(),
    getColorThemeUsingAdminDetailSaga(),
    profileSagas(),
    getProfileDetailSaga(),
    getColleaguesAndTeamDetailSaga(),
    addColleaguesAndTeamSagas(),
    getColleaguesAndTeamDetailSagaList(),
    getRewardSaga(),
    RewardListingSaga(),
    getPremiumPlanSaga(),
    searchColleaguesAndTeamDetailSaga(),
    BusinessInfoSagas(),
    GalleyPhotoDeleteSagas(),
    gallaryPhotoUploadSagas(),
    countryCodeListSagas(),
    PhoneVerificationSaga(),
    PhoneVerificationOtpSagas(),
    deleteColleaguesSagas(),
    AnalyticsDetailsSagas(),
    AnalyticsDailyCountsSagas(),
    AnalyticsMonthCountsSagas(),
    subscriptionPlanSagas(),
    epnterpriseOfferSagas(),
    VcardDownloadSagas(),
    preminPlanViewSaga(),
  ]);
}
