import {
  START_SAVE_PROFILE,
  SUCCESS_SAVE_PROFILE,
  ERROR_SAVE_PROFILE,
  FETCH_PROFILE_DETAIL,
  GET_PROFILE_DETAIL,
  ERROR_PROFILE_DETAIL,
  EMPTY_MESSAGE,
  START_GALLARY_PHOTO_UPLOAD,
  SUCCESS_GALLARY_PHOTO_UPLOAD,
  ERROR_GALLARY_PHOTO_UPLOAD,
  FETCH_COUNTRY_LIST,
  GET_COUNTRY_LIST,
  ERROR_COUNTRY_LIST,
  EMPTY_ERROR_BAG,
} from "../actions/ProfileActions";

const initialState = {
  isLoading: false,
  isError: false,
  profileSuccessMessage: "",
  profileErrorMessage: "",
  isProfileDetail: false,
  profileDetail: {},
  isGallaryPhotoloading: false,
  gallaryPhotoSucessMessage: "",
  gallaryPhotoErrorMessage: "",
  newAddedGalleryPhoto: {},
  isCountryList: false,
  countryList: {},
  countryListErrorMessage: "",
  errorBag: {},
};

const ProfileReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Save Profile details case
    case START_SAVE_PROFILE: {
      return {
        ...prevState,
        isLoading: true,
        isError: false,
      };
    }
    case SUCCESS_SAVE_PROFILE: {
      return {
        ...prevState,
        isLoading: false,
        isError: false,
        profileSuccessMessage: action.message,
        profileDetail: action.profileDetail,
      };
    }
    case ERROR_SAVE_PROFILE: {
      return {
        ...prevState,
        isLoading: false,
        isError: true,
        profileErrorMessage: action,
        errorBag: action.errorBag,
      };
    }
    //END: Save Profile details case
    //START: Get Profile details case
    case FETCH_PROFILE_DETAIL: {
      return {
        ...prevState,
        isProfileDetail: true,
      };
    }
    case GET_PROFILE_DETAIL:
      return {
        ...prevState,
        isLoggedIn: true,
        isProfileDetail: false,
        profileDetail: action.payload,
      };
    case ERROR_PROFILE_DETAIL:
      return {
        ...prevState,
        profileDetail: {},
        isProfileDetail: false,
      };
    //END: Get Profile details case
    //START gallary Photo upload
    case START_GALLARY_PHOTO_UPLOAD: {
      return {
        ...prevState,
        isGallaryPhotoloading: true,
        isError: false,
      };
    }
    case SUCCESS_GALLARY_PHOTO_UPLOAD: {
      return {
        ...prevState,
        isGallaryPhotoloading: false,
        isError: false,
        gallaryPhotoSucessMessage: action.message,
        newAddedGalleryPhoto: action.photoGalleryDetail.photo_gallery_data,
      };
    }
    case ERROR_GALLARY_PHOTO_UPLOAD: {
      return {
        ...prevState,
        isGallaryPhotoloading: false,
        isError: true,
        gallaryPhotoErrorMessage: action.message,
      };
    }
    //END
    //START: country Code List
    case FETCH_COUNTRY_LIST: {
      return {
        ...prevState,
        //isCountryList: true,
      };
    }
    case GET_COUNTRY_LIST:
      return {
        ...prevState,
        //isCountryList: false,
        countryList: action.payload,
      };
    case ERROR_COUNTRY_LIST:
      return {
        ...prevState,
        countryList: {},
        isCountryList: false,
        countryListErrorMessage: "Something went wrong!",
      };
    //END: country Code List
    //START: Notification Empty Message
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //END: Notification Empty Message

    //START: Error Empty Message
    case EMPTY_ERROR_BAG: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    //END: Error Empty Message
    default:
      return prevState;
  }
};

export default ProfileReducer;
