import API from "../api";

//Premium Plan Listing API

export function PremiumPlansList() {
  return API.get("/premium_plans");
}

export function PremiumPlansListView(userId) {
  return API.get(`/premium_plans/${userId}`);
}

//Premium Plan Post API

export function PremiumPlansSubscribe(payload) {
  return API.post("/subscribe", payload);
}

// Enterprise Post API
export function enterpriseOffer(name, phone_no, email) {
  return API.post("/enterprise/save", {
    name,
    phone_no,
    email,
  });
}
