import {
  FETCH_REWARDS_POINTS,
  GET_REWARDS_POINTS_DETAIL,
  ERROR_REWARDS_POINTS_DETAIL,
  START_REWARDS_CLAIM,
  SUCCESS_REWARDS_CLAIM,
  ERROR_REWARDS_CLAIM,
  EMPTY_MESSAGE,
} from "../actions/RewardPointsActions";

const initialState = {
  isRewardsPointsDetail: false,
  rewardsPointsDetail: {},
  rewardsPointsSuccess: "",
  rewardsPointsErrorMessage: "",
  isLoadingRewardsPoints: false,
  errorBag: {}
};

const RewardsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    //START: Reward Points details case
    case FETCH_REWARDS_POINTS: {
      return {
        ...prevState,
        isRewardsPointsDetail: true,
      };
    }
    case GET_REWARDS_POINTS_DETAIL:
      return {
        ...prevState,
        isRewardsPointsDetail: false,
        rewardsPointsDetail: action.payload,
      };
    case ERROR_REWARDS_POINTS_DETAIL:
      return {
        ...prevState,
        rewardsPointsDetail: {},
        isRewardsPointsDetail: false,
      };
    //END:  Reward Points  details case
    //START: Get Reward Points
    case START_REWARDS_CLAIM: {
      return {
        ...prevState,
        isLoadingRewardsPoints: true,
      };
    }
    case SUCCESS_REWARDS_CLAIM: {
      return {
        ...prevState,
        isLoadingRewardsPoints: false,
        rewardsPointsSuccess: action.message,
        //rewardsPointsDetail: action.rewardsPointsDetail,
      };
    }
    case ERROR_REWARDS_CLAIM: {
      return {
        ...prevState,
        isLoadingRewardsPoints: false,
        rewardsPointsErrorMessage: action.message,
        errorBag: action.errorBag
      };
    }
    //END: Get Reward Points
    case EMPTY_MESSAGE: {
      return {
        ...prevState,
        [action.key]: initialState[action.key],
      };
    }
    default:
      return prevState;
  }
};

export default RewardsReducer;
