import API from "../api";

//Colleague Add  API

export function colleaguesAndTeamAdd(u_id) {
    return API.post("/save_team_colleague",
        {
            u_id,
        });
}

//Colleague Add  Listing API

export function getcolleaguesAndTeam() {
    return API.get("/get_team_colleagues_data");
}

//Colleague List API
export function getcolleaguesAndTeamList(userId) {
    return API.get(`/get_business_info/team_and_colleagues_info/${userId}`);
}


//Remove Colleagues ApI
export function deleteColleague(userId) {
    return API.get(`/delete_colleague/${userId}`);
}
