import { call, put, take } from "redux-saga/effects";
import {
    START_SAVE_FEEDBACK,
    SUCCESS_SAVE_FEEDBACK,
    ERROR_SAVE_FEEDBACK
} from '../actions/FeedbackActions';
import {
    feedbackSave
} from '../../api/feedbackApi';




//START: Feedback 
export function* feedbackSagas() {
    while (true) {
        const { rate, typeFeedback, feedbackMessage } = yield take(START_SAVE_FEEDBACK);
        const response = yield call(feedbackSave, rate, typeFeedback, feedbackMessage);
        const { message } = response;
        if (response.status) {
            yield put({
                type: SUCCESS_SAVE_FEEDBACK,
                message,
            })
        }
        else {
            yield put({
                type: ERROR_SAVE_FEEDBACK,
                message: message || 'Something went wrong, please try again',
                errorsBag: response.data.errors || {}
            })
        }
    }
}

//END:Feedback