import { call, put, take } from "redux-saga/effects";
import {
  FETCH_REWARDS_POINTS,
  GET_REWARDS_POINTS_DETAIL,
  ERROR_REWARDS_POINTS_DETAIL,
  START_REWARDS_CLAIM,
  SUCCESS_REWARDS_CLAIM,
  ERROR_REWARDS_CLAIM,
} from "../actions/RewardPointsActions";
import { RewardPointsList, GetRewardPoints } from "../../api/rewardsPointsApi";

//START:Rewards data details
export function* RewardListingSaga() {
  while (true) {
    yield take(FETCH_REWARDS_POINTS);
    const response = yield call(RewardPointsList);
    if (response.status) {
      yield put({
        type: GET_REWARDS_POINTS_DETAIL,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_REWARDS_POINTS_DETAIL,
      });
    }
  }
}
//END:Rewards data details
export function* getRewardSaga() {
  while (true) {
    const { formData } = yield take(START_REWARDS_CLAIM);
    const response = yield call(GetRewardPoints, formData);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_REWARDS_CLAIM,
        message,
        rewardsPointsDetail: response.data,
      });
    } else {
      yield put({
        type: ERROR_REWARDS_CLAIM,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}
