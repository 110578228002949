import API from "../api";

//Reards Points Listing API
export function RewardPointsList() {
  return API.get("/rewards_points");
}

//Reards Points Claim
export function GetRewardPoints(formData) {
  return API.postWithFormData("/claimreward", formData);
}
