import { call, put, take } from "redux-saga/effects";
import {
  START_COLLEAGUES_TEAM,
  SUCCESS_COLLEAGUES_TEAM,
  ERROR_COLLEAGUES_TEAM,
  FETCH_COLLEAGUES_TEAM,
  GET_COLLEAGUES_TEAM_DETAIL,
  ERROR_COLLEAGUES_TEAM_DETAIL,
  FETCH_VISTOR_COLLEAGUE_TEAM,
  GET_VISTOR_COLLEAGUE_TEAM,
  ERROR_VISTOR_COLLEAGUE_TEAM,
  START_DELETE_COLLEAGUES,
  SUCCESS_DELETE_COLLEAGUES,
  ERROR_DELETE_COLLEAGUES,
} from "../actions/ColleaguesAndTeamActions";
import {
  colleaguesAndTeamAdd,
  getcolleaguesAndTeam,
  getcolleaguesAndTeamList,
  deleteColleague,
} from "../../api/colleagueAndTeamApi";

//START: Add Colleagues Team

export function* addColleaguesAndTeamSagas() {
  while (true) {
    const { u_id } = yield take(START_COLLEAGUES_TEAM);
    const response = yield call(colleaguesAndTeamAdd, u_id);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_COLLEAGUES_TEAM,
        message,
      });

      yield put({
        type: FETCH_COLLEAGUES_TEAM,
      });
    } else {
      yield put({
        type: ERROR_COLLEAGUES_TEAM,
        message: message || "Something went wrong, please try again",
      });
    }
  }
}

//END: Add Colleagues Team
//START: Get Add Colleagues Team
export function* getColleaguesAndTeamDetailSaga() {
  while (true) {
    yield take(FETCH_COLLEAGUES_TEAM);
    const response = yield call(getcolleaguesAndTeam);
    if (response.status) {
      yield put({
        type: GET_COLLEAGUES_TEAM_DETAIL,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_COLLEAGUES_TEAM_DETAIL,
      });
    }
  }
}

//END:Get Add Colleagues Team

//START: Get Add Colleagues Team
export function* getColleaguesAndTeamDetailSagaList() {
  while (true) {
    const { userId } = yield take(FETCH_VISTOR_COLLEAGUE_TEAM);
    const response = yield call(getcolleaguesAndTeamList, userId);
    if (response.status) {
      yield put({
        type: GET_VISTOR_COLLEAGUE_TEAM,
        payload: response.data,
        themeData: response?.theme_color,
      });
    } else {
      yield put({
        type: ERROR_VISTOR_COLLEAGUE_TEAM,
        message: response.message,
        errorBucket: response.data,
        isValidUserId: false,
      });
    }
  }
}

//START: Delete Colleague
export function* deleteColleaguesSagas() {
  while (true) {
    const { userId } = yield take(START_DELETE_COLLEAGUES);
    const response = yield call(deleteColleague, userId);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_DELETE_COLLEAGUES,
        message,
      });
    } else {
      yield put({
        type: ERROR_DELETE_COLLEAGUES,
        message: message || "Something went wrong, please try again",
      });
    }
  }
}
//END:Photo Delete
