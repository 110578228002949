//Listing Rewards Points
export const FETCH_REWARDS_POINTS = "FETCH_REWARDS_POINTS";
export const GET_REWARDS_POINTS_DETAIL = "GET_REWARDS_POINTS_DETAIL";
export const ERROR_REWARDS_POINTS_DETAIL = "ERROR_REWARDS_POINTS_DETAIL";

//Get Reward point
export const START_REWARDS_CLAIM = "START_REWARDS_CLAIM";
export const SUCCESS_REWARDS_CLAIM = "SUCCESS_REWARDS_CLAIM";
export const ERROR_REWARDS_CLAIM = "ERROR_REWARDS_CLAIM";

//empty
export const EMPTY_MESSAGE = "EMPTY_MESSAGE"