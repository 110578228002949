import { call, put, take } from "redux-saga/effects";
import {
  FETCH_PREMIUM_PLANS,
  GET_PREMIUM_PLANS_DETAIL,
  ERROR_PREMIUM_PLANS_DETAIL,
  START_ENTERPRISE_OFFER,
  SUCCESS_ENTERPRISE_OFFER,
  ERROR_ENTERPRISE_OFFER,
  FETCH_PREMIUM_PLANS_VIEW,
  GET_PREMIUM_PLANS_DETAIL_VIEW,
  ERROR_PREMIUM_PLANS_DETAIL_VIEW,
} from "../actions/PremiumPlansActions";
import {
  PremiumPlansList,
  enterpriseOffer,
  PremiumPlansListView,
} from "../../api/premiumPlansApi";

//START:Premium plans  details
export function* getPremiumPlanSaga() {
  while (true) {
    yield take(FETCH_PREMIUM_PLANS);
    const response = yield call(PremiumPlansList);
    if (response.status) {
      yield put({
        type: GET_PREMIUM_PLANS_DETAIL,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_PREMIUM_PLANS_DETAIL,
      });
    }
  }
}
//END:Premium plans  details
export function* preminPlanViewSaga() {
  while (true) {
    const { userId } = yield take(FETCH_PREMIUM_PLANS_VIEW);
    const response = yield call(PremiumPlansListView, userId);
    if (response.status) {
      yield put({
        type: GET_PREMIUM_PLANS_DETAIL_VIEW,
        payload: response.data,
      });
    } else {
      yield put({
        type: ERROR_PREMIUM_PLANS_DETAIL_VIEW,
      });
    }
  }
}

//START: Enterprise offer
export function* epnterpriseOfferSagas() {
  while (true) {
    const { name, phone_no, email } = yield take(START_ENTERPRISE_OFFER);
    const response = yield call(enterpriseOffer, name, phone_no, email);
    const { message } = response;
    if (response.status) {
      yield put({
        type: SUCCESS_ENTERPRISE_OFFER,
        message,
      });
    } else {
      yield put({
        type: ERROR_ENTERPRISE_OFFER,
        message: message || "Something went wrong, please try again",
        errorBag: response.data.error || {},
      });
    }
  }
}
//END:Enterprise offer
