//Profile Actions
export const START_SAVE_PROFILE = "START_SAVE_PROFILE"
export const SUCCESS_SAVE_PROFILE = "SUCCESS_SAVE_PROFILE"
export const ERROR_SAVE_PROFILE = "ERROR_SAVE_PROFILE"

//Error Empty Message
export const EMPTY_ERROR_BAG = "EMPTY_ERROR_BAG"

//Profile Details
export const FETCH_PROFILE_DETAIL = "FETCH_PROFILE_DETAIL"
export const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL"
export const ERROR_PROFILE_DETAIL = "ERROR_PROFILE_DETAIL"


//empty
export const EMPTY_MESSAGE = "EMPTY_MESSAGE"

//Gallery Photo Upload 
export const START_GALLARY_PHOTO_UPLOAD = "START_GALLARY_PHOTO_UPLOAD";
export const SUCCESS_GALLARY_PHOTO_UPLOAD = "SUCCESS_GALLARY_PHOTO_UPLOAD";
export const ERROR_GALLARY_PHOTO_UPLOAD = "ERROR_GALLARY_PHOTO_UPLOAD";

//Delete Photo
export const START_DELETE_PHOTO = "START_DELETE_PHOTO";
export const SUCCESS_DELETE_PHOTO = "SUCCESS_DELETE_PHOTO";
export const ERROR_DELETE_PHOTO = "ERROR_DELETE_PHOTO";

//country Code List
export const FETCH_COUNTRY_LIST = "FETCH_COUNTRY_LIST"
export const GET_COUNTRY_LIST = "GET_COUNTRY_LIST"
export const ERROR_COUNTRY_LIST = "ERROR_COUNTRY_LIST"