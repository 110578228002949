import { call, put, take } from "redux-saga/effects";
import {
    START_DELETE_PHOTO,
    SUCCESS_DELETE_PHOTO,
    ERROR_DELETE_PHOTO
} from '../actions/ProfileActions';
import {
    GallearyPhotoDelete
} from '../../api/ProfileApi';



//START: Photo Delete 
export function* GalleyPhotoDeleteSagas() {
    while (true) {
        const { id } = yield take(START_DELETE_PHOTO);
        const response = yield call(GallearyPhotoDelete, id);
        const { message } = response;
        if (response.status) {
            yield put({
                type: SUCCESS_DELETE_PHOTO,
                message,
            })
        }
        else {
            yield put({
                type: ERROR_DELETE_PHOTO,
                message: message || 'Something went wrong, please try again',
            })
        }
    }
}
//END:Photo Delete
