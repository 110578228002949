import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CF from "./utils/CommonFunction";
import { connect } from "react-redux";
import { FETCH_USER_LOGGED_IN_DETAIL } from "./redux/actions/User";
import { routeConstants } from "./constants/";
import PageLoading from "./components/Web/PageLoading";
import { FETCH_COLOR_USING_ADMIN_THEME } from "./redux/actions/ColorThemeActions";
const Login = React.lazy(() => import("./container/Login/Index"));
const PhoneVerification = React.lazy(() =>
  import("./container/PhoneVerification/Index")
);
const SettingPage = React.lazy(() => import("./container/Setting/Index"));
const ChangePassword = React.lazy(() =>
  import("./container/ChangePassword/Index")
);
const Feedback = React.lazy(() => import("./container/Feedback/Index"));
const FeedBackSuccess = React.lazy(() =>
  import("./container/Feedback/FeedbackSuccess")
);
const BusinessInfo = React.lazy(() => import("./container/BusinessInfo/Index"));
const Profile = React.lazy(() => import("./container/Profile/Index"));
const ColleaguesTeamAdd = React.lazy(() =>
  import("./container/Colleagues&TeamAdd/Index")
);
const ThemeColor = React.lazy(() => import("./container/Theme/Index"));
const PremiumPlans = React.lazy(() => import("./container/PremiumPlans/Index"));
const RewardsAndBenefits = React.lazy(() =>
  import("./container/RewardsAndBenefits/Index")
);
const Analytics = React.lazy(() => import("./container/Analytics/Index"));
const TeamAndColleagues = React.lazy(() =>
  import("./container/ColleaguesAndTeam/Index")
);
const InternalSeverError = React.lazy(() =>
  import("./components/Common/InternalSeverError")
);
const NotFound = React.lazy(() => import("./components/Common/NotFound"));
const PrivateRoute = React.lazy(() =>
  import("./components/Common/PrivateRoute")
);
const ClaimSuccess = React.lazy(() =>
  import("./container/RewardsAndBenefits/ClaimSuccess")
);
const Advertisement = React.lazy(() =>
  import("./container/Advertisement/Index")
);

function Routes(props) {
  const { getUserDetail, getColorUsingAdminThemeDetail } = props;

  useEffect(() => {
    if (CF.isLoggedIn()) {
      getUserDetail();
      getColorUsingAdminThemeDetail();
    }
  }, []);

  return (
    <Suspense fallback={<PageLoading showMessage={false} />}>
      <Router>
        <Switch>
          <Route
            path={routeConstants.LOGIN.route}
            exact
            render={(props) => <Login />}
          />

          <Route
            path={routeConstants.PHONE_VERIFICATION.route}
            exact
            component={PhoneVerification}
          />

          <PrivateRoute
            path={routeConstants.CHANGE_PASSWORD.route}
            exact
            component={ChangePassword}
          />
          <PrivateRoute
            path={routeConstants.SETTING.route}
            exact
            component={SettingPage}
          />
          <PrivateRoute
            path={routeConstants.FEEDBACK.route}
            exact
            component={Feedback}
          />
          <PrivateRoute
            path={routeConstants.FEEDBACK.subRoutes.SUCCESS.route}
            exact
            component={FeedBackSuccess}
          />
          <PrivateRoute
            path={routeConstants.CLAIM_SUCCESS.route}
            exact
            component={ClaimSuccess}
          />
          <PrivateRoute
            path={routeConstants.ADVERTISEMENT.route}
            exact
            component={Advertisement}
          />
          <PrivateRoute
            path={routeConstants.THEME_COLOR.route}
            exact
            component={ThemeColor}
          />
          <PrivateRoute
            path={routeConstants.REWARD.route}
            exact
            component={RewardsAndBenefits}
          />
          <Route
            path={routeConstants.TEAM_COLLEAGUE.route}
            exact
            component={TeamAndColleagues}
          />
          <PrivateRoute
            path={routeConstants.COLLEAGUE_TEAM_ADD.route}
            exact
            component={ColleaguesTeamAdd}
          />
          <PrivateRoute
            path={routeConstants.PREMIUM_PLANS.route}
            exact
            component={PremiumPlans}
          />
          <Route
            path={routeConstants.BUSINESS_INFO.route}
            exact
            component={BusinessInfo}
          />
          <PrivateRoute
            path={routeConstants.PROFILE.route}
            exact
            component={Profile}
          />
          <PrivateRoute
            path={routeConstants.ANALYTICS.route}
            exact
            component={Analytics}
          />
          <Route
            path={routeConstants.NOT_FOUND.route}
            exact
            component={NotFound}
          />
          <Route
            path={routeConstants.INTERNAL_SERVER_ERROR.route}
            exact
            component={InternalSeverError}
          />
          <Route
            path={routeConstants.PREMIUM_PLANS_TO_ANOTHER_PAGE.route}
            exact
            component={PremiumPlans}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getUserDetail: () =>
    dispatch({
      type: FETCH_USER_LOGGED_IN_DETAIL,
    }),
  getColorUsingAdminThemeDetail: () =>
    dispatch({
      type: FETCH_COLOR_USING_ADMIN_THEME,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
